import { Action } from "@ngrx/store";

export enum ActionTypes{
  AccountVerification = '[Alert] Verification',
  AccountVerificationContinue = '[Alert] VerificationContinue',
  AccountReview = '[Alert] AccountReview',
  AccountAccepted = '[Alert] AccountAccepted',
  OrderCard = '[Alert] OrderCard',
  OrderSuccess = '[Alert] OrderSuccess',
  LoadSuccess = '[Alert] LoadSuccess',
  ReloadSuccess = '[Alert] ReloadSuccess',
  RenewSuccess = '[Alert] RenewSuccess',
  SetAlertAction = '[Alert] SetAlertAction',
  TransferSuccess = '[Alert] TransferSuccess',
  TransferError = '[Alert] TransferError',
  Clear = '[Alert] Clear'
}

export class AccountVerification implements Action{
    readonly type = ActionTypes.AccountVerification;
}

export class AccountVerificationContinue implements Action{
  readonly type = ActionTypes.AccountVerificationContinue;
}

export class AccountReview implements Action{
  readonly type = ActionTypes.AccountReview;
}

export class AccountAccepted implements Action{
  readonly type = ActionTypes.AccountAccepted;
}

export class OrderCard implements Action{
  readonly type = ActionTypes.OrderCard;
}

export class OrderSuccess implements Action{
  readonly type = ActionTypes.OrderSuccess;
}

export class LoadSuccess implements Action{
  readonly type = ActionTypes.LoadSuccess;
}
export class ReloadSuccess implements Action{
  readonly type = ActionTypes.ReloadSuccess;
}

export class RenewSuccess implements Action{
  readonly type = ActionTypes.RenewSuccess;
}

export class SetAlertAction implements Action{
  readonly type = ActionTypes.SetAlertAction;

  constructor(public alertAction: string){}
}

export class TransferSuccess implements Action{
  readonly type = ActionTypes.TransferSuccess;
  
  constructor(public message: string){}
}

export class TransferError implements Action{
  readonly type = ActionTypes.TransferError;
  
  constructor(public errorMessage: string){}
}

export class Clear implements Action{
  readonly type = ActionTypes.Clear;
}

export type ActionsUnion = AccountVerification|AccountVerificationContinue|AccountReview|AccountAccepted|OrderCard|OrderSuccess|ReloadSuccess|RenewSuccess|SetAlertAction|TransferSuccess|TransferError|Clear|LoadSuccess;