import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Observable, of, EMPTY } from "rxjs";
import { switchMap, map, catchError, filter, tap } from "rxjs/operators";
import { ActionTypes, UpdateAddressAPI, UpdateLegalAPI } from "../actions/persons.actions";
import { Action } from '@ngrx/store';
import { PersonService } from '../../api/person.service';

@Injectable()
export class PersonEffects {

  constructor(private actions$: Actions, private personService: PersonService) { }

  @Effect()
  getPerson$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.GetPersonAPI),
    switchMap(action => {
      return this.personService.getPersonDetails().pipe(
        map(data => ({ type: ActionTypes.SetPerson, payload: data })),
        catchError(() => EMPTY)
      )
    })
  )


  @Effect()
  updateAddress$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.UpdateAddressAPI),
    switchMap((action: UpdateAddressAPI) => {
      return this.personService.updateAddress(action.person).pipe(
        map(data => {
          return ({ type: ActionTypes.SetPerson, payload: data })
        }),
        catchError(() => {
          return EMPTY;
        })
      )
    })
  )

  @Effect()
  updateLegal$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.UpdateLegalAPI),
    switchMap((action: UpdateLegalAPI) => {
      return this.personService.updateLegal(action.person).pipe(
        map(data => {
          return ({ type: ActionTypes.SetPerson, payload: data })
        }),
        catchError(() => {
          return EMPTY;
        })
      )
    })
  )
}
