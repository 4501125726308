import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountVerification, AccountReview, OrderCard, OrderSuccess, Clear, SetAlertAction, AccountVerificationContinue, ReloadSuccess, RenewSuccess, AccountAccepted, TransferError, TransferSuccess, LoadSuccess } from '../actions/alert.actions';
import { Alert } from '../models/alert';
import { getAlert, getAlertAction } from '../reducers/alert.reducer';
import { WalletToWalletRequest } from '../../api/cards.service';

@Injectable({
  providedIn: 'root'
})
export class AlertStoreService {

  constructor(private store:Store<{alert:Alert}>) { }

  accountVerification(){
    this.store.dispatch(new AccountVerification());
  }

  accountVerificationContinue(){
    this.store.dispatch(new AccountVerificationContinue());
  }

  accountReview(){
    this.store.dispatch(new AccountReview());
  }

  accountAccepted(){
    this.store.dispatch(new AccountAccepted());
  }

  orderCard(){
    this.store.dispatch(new OrderCard());
  }

  orderSuccess(){
    this.store.dispatch(new OrderSuccess());
  }

  loadSuccess(){
    this.store.dispatch(new LoadSuccess());
  }

  reloadSuccess(){
    this.store.dispatch(new ReloadSuccess());
  }

  renewSuccess(){
    this.store.dispatch(new RenewSuccess());
  }

  setAlertAction(action: string){
    this.store.dispatch(new SetAlertAction(action));
  }

  clearAlert(){
    this.store.dispatch(new Clear());
  }

  getAlert(){
    return this.store.select(getAlert);
  }

  getAlertAction(){
    return this.store.select(getAlertAction);
  }

  transferSuccess(message: string){
    this.store.dispatch(new TransferSuccess(message));
  }

  transferError(errorMessage: string){
    this.store.dispatch(new TransferError(errorMessage));
  }
}
