import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'cp-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit {

  home:boolean;
  support:boolean;
  constructor(private authService:AuthenticationService) { }

  ngOnInit() {
    this.home = true;
  }

  logout(){
    this.authService.logout();
  }

}
