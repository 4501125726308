<mat-toolbar>
    <mat-toolbar-row>
        <div class="logo"></div>
        <div class="buttons" *ngIf="(screen$ | async)?.login">
            <button class="mat-elevation-z4" mat-raised-button (click)="loginScreen()">Log In</button>
            <button class="mat-elevation-z4" mat-stroked-button (click)="signupScreen()">Sign Up</button>
        </div>
        <div class="buttons" *ngIf="(screen$ | async)?.signup">
            <button  mat-stroked-button (click)="loginScreen()">Log In</button>
            <button class="mat-elevation-z4" mat-raised-button (click)="signupScreen()">Sign Up</button>
        </div>
        <div class="buttons" *ngIf="(screen$ | async)?.both">
                <button  mat-stroked-button (click)="loginScreen()">Log In</button>
                <button  mat-stroked-button (click)="signupScreen()">Sign Up</button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>