import { Injectable } from '@angular/core';
import { Observable, interval, Subscription, fromEvent, merge } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { TimeoutWarningComponent } from 'src/app/shared/dialogs/timeout-warning/timeout-warning.component'
import { AuthenticationService } from './authentication.service'
import { PersonService } from '../api/person.service';

@Injectable({
    providedIn: 'root'
  })
export class SessionTimeoutService {
    eventSub: Subscription;
    dialogRef: MatDialogRef<TimeoutWarningComponent>

    idleTime: number
    lastActionTime: number

    counter$: Observable<number>
    observableEvents$: Observable<any>;
    timer: Subscription
    timerSubscription = new Subscription()

    constructor(
        private dialog: MatDialog,
        private personService: PersonService,
        private authService: AuthenticationService
    ) { }

    public activityEvent: Array<any>[] = [
        [document, 'click'],
        [document, 'wheel'],
        [document, 'scroll'],
        [document, 'mousemove'],
        [document, 'keyup'],
        [window, 'resize'],
        [window, 'scroll'],
        [window, 'mousemove']];

    startTimer() {
        const warningTime = 3*60 - 1
        const maximumTime = warningTime + 61
        const seconds = 1000
        this.lastActionTime = 0

        if(this.timer !== undefined) { this.stopTimer() }
        this.counter$ = interval(seconds).pipe(take(maximumTime))

        this.timer = this.counter$.subscribe(
            (counter) => {
                this.idleTime = counter

                if ( this.authService.isLoggedOut() ) {
                    this.logout()
                    this.stopTimer()
                    return
                }

                if (this.idleTime === warningTime) { // four minutes
                    if (this.lastActionTime === 0) {
                        this.openDialog()
                    } else { // bundle with warning to reduce the number of network calls
                        this.refreshSession()
                        this.idleTime -= this.lastActionTime
                    }
                }
                if (this.idleTime === maximumTime - 1) { // five minutes
                    this.logout()
                }

            },
            () => {}, // ERROR
            () => {this.timer.unsubscribe()}) // COMPLETE

        this.detectActivity()
    }

    detectActivity() {
        let observableEventArray$: Observable<any>[] = []
        this.activityEvent.forEach(
            (x) => { observableEventArray$.push(fromEvent(x[0], x[1])) })
        this.observableEvents$ = merge(...observableEventArray$)
        this.eventSub = this.observableEvents$.subscribe(
            ( ) => { this.lastActionTime = this.idleTime })
        this.timer.add(this.eventSub)
    }

    stopTimer() {
        this.timer.unsubscribe()
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig()
        dialogConfig.panelClass = 'timeout-warning-dialog-container'
        dialogConfig.width = '500px'
        dialogConfig.height = '420px'
        dialogConfig.disableClose = true
        this.dialogRef = this.dialog.open(TimeoutWarningComponent, dialogConfig)
    }
    
    refreshSession () {
        this.personService.getPersonDetails().subscribe(() => {},
        () => {console.log('failed to refresh session.')})
        if (this.dialogRef) {
            this.dialogRef.close()
        }
    }

    logout() {
        this.dialogRef.close()
        this.authService.logout()
    }

}