import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { SessionTimeoutService } from './../../../core/services/session-timeout.service';

@Component({
  selector: 'cp-timeout-warning',
  templateUrl: './timeout-warning.component.html',
  styleUrls: ['./timeout-warning.component.scss','./../../styles/dialogs.scss']
})
export class TimeoutWarningComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private SessionTimeoutService: SessionTimeoutService,
    private dialogRef: MatDialogRef<TimeoutWarningComponent>,
    ) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout()
    this.dialogRef.close()
  }

  refreshSession () {
    this.SessionTimeoutService.refreshSession()
  }

}
