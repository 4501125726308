import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cp-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss']
})
export class AuthFooterComponent implements OnInit {

  constructor() { }

  copyrightdate:number;

  ngOnInit() {
    this.copyrightdate = new Date().getFullYear();
  }

}
