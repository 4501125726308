import {ActionTypes,ActionsUnion} from "../actions/alert.actions";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Alert } from '../models/alert';
import { AlertAction } from '../../enums/alertAction';

const initialState:Alert = {
    show: false,
    message: '',
    icon: '',
    actionText: '',
    action: '',
    background: ''
}

export function reducer(state:Alert = initialState,action: ActionsUnion):Alert{
    switch(action.type){
        case ActionTypes.AccountVerification:{
            return {
                ...state,
                show: true,
                message: 'Welcome to DPO Card, please complete your legal verifictaion before ordering a card.',
                icon: 'error_outline',
                actionText: AlertAction.GET_STARTED,
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.AccountVerificationContinue:{
            return {
                ...state,
                show: true,
                message: 'Continue editing your legal verification details so that you can order a card',
                icon: 'error_outline',
                actionText: AlertAction.CONTINUE_EDITING,
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.AccountReview:{
            return {
                ...state,
                show: true,
                message: 'Thanks for submitting all your details. Your account is currently in review…',
                icon: 'notifications_paused',
                actionText: '',
                action: '',
                background: 'blue-toolbar'
            }
        }
        case ActionTypes.AccountAccepted:{
            return {
                ...state,
                show: true,
                message: 'Your account has been approved. We have ordered your physical DPO Card',
                icon: 'notifications_paused',
                actionText: '',
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.OrderCard:{
            return {
                ...state,
                show: true,
                message: 'Get started by ordering your first DPO Virtual Card.',
                icon: '',
                actionText: '',
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.OrderSuccess:{
            return {
                ...state,
                show: true,
                message: 'Your DPO Virtual Card has been successfully created.',
                icon: '',
                actionText: AlertAction.VIEW_CARD_DETAILS,
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.LoadSuccess:{
            return {
                ...state,
                show: true,
                message: 'Your Card has been successfully loaded.',
                icon: '',
                actionText: '',
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.ReloadSuccess:{
            return {
                ...state,
                show: true,
                message: 'Your DPO Virtual Card has been successfully reloaded.',
                icon: '',
                actionText: AlertAction.VIEW_CARD_DETAILS,
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.RenewSuccess:{
            return {
                ...state,
                show: true,
                message: 'Your DPO Virtual Card has been successfully renewed.',
                icon: '',
                actionText: AlertAction.VIEW_CARD_DETAILS,
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.TransferSuccess:{
            return {
                ...state,
                show: true,
                message: action.message,
                icon: '',
                actionText: '',
                action: '',
                background: 'green-toolbar'
            }
        }
        case ActionTypes.TransferError:{
            return {
                ...state,
                show: true,
                message: action.errorMessage,
                icon: '',
                actionText: '',
                action: '',
                background: 'red-toolbar'
            }
        }
        case ActionTypes.SetAlertAction:{
            return {
                ...state,
                action: action.alertAction
            }
        }
        case ActionTypes.Clear:{
            return {
                ...state,
                show: false,
                message: '',
                icon: '',
                actionText: '',
                action: '',
                background: ''
            }
        }
        default:
            return state;
    }
}

export const getAlert = createFeatureSelector<Alert>('alert');

export const getAlertAction = createSelector(
    getAlert,
    (alert: Alert) => alert.action
);