import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Person } from 'src/app/core/interfaces/person';
@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private http: HttpClient) {
  }

  personUrl = environment.apiUrl + '/persons';
  updatePersonUrl = environment.apiUrl + '/persons/person';
  personPictureUrl = environment.apiUrl + '/persons/picture';
  sendEmailVerificationUrl = environment.apiUrl + '/persons/person/email/verify/send';
  updateAddressUrl = environment.apiUrl + '/persons/person/address';
  updateLegalUrl = environment.apiUrl + '/persons/person/legal';

  getPersonDetails() {
    return this.http.get<Person>(this.personUrl).pipe();
  }

  updatePersonDetails(person: Person) {
    return this.http.put(this.updatePersonUrl, person).pipe();
  }

  updateAddress(person: any) {
    return this.http.patch(this.updateAddressUrl, person).pipe();
  }

  updateLegal(person: any) {
    return this.http.patch(this.updateLegalUrl, person).pipe();
  }

  verifyEmail(personDetailId: string, data: string[]) {
    let counter = 0;
    let token = "";
    data.forEach(el => {
      if (counter < 5) {
        token += el;
        if (counter != 4) {
          token += "-";
        }
        counter++;
      }
    });

    const headers = new HttpHeaders()
      .set('X-FORM-KEY', btoa(token));
    return this.http.get(environment.apiUrl + `/persons/${personDetailId}/email/verify`, { headers }).pipe();
  }

  sendVerifyEmail(personDetailId: string, newEmail: string) {
    const params = new HttpParams().append('pdi', personDetailId).append('email', newEmail);
    return this.http.get(this.sendEmailVerificationUrl, { params }).pipe();
  }

  getPicture() {
    return this.http.get(this.personPictureUrl, { responseType: 'text' }).pipe();
  }

  setPicture(document: any) {
    return this.http.post(this.personPictureUrl, document).pipe();
  }

  getMaritalStatus(maritalStatus: string) {
    switch (maritalStatus) {
      case 'S':
        return 'Single';
      case 'M':
        return 'Married';
      case 'D':
        return 'Divorced';
      case 'W':
        return 'Widow/Widower';
      default:
        return maritalStatus;
    }
  }

  getGender(gender: string) {
    switch (gender) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      default:
        return gender;
    }
  }

  getIdType(idType: string) {
    switch (idType) {
      case 'ID':
        return 'National ID';
      case 'PP':
        return 'Passport';
      default:
        return idType;
    }
  }
}
