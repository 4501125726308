import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


export class Currency{
  id:number;
  currency:string;
  code:string;
  numeric:string;
  isActive:boolean;
}

export class Country{
  id:number;
  country:string;
  alpha2:string;
  alpha3:string;
  numeric:string;
  idc:string;
  isActive:boolean;
}

export class Program{
  id:number;
  programName:string;
  programCode:string;
  country:Country;
  currencies:Currency[];
}

export class Title {
  value: string;
  viewValue: string;
}

export interface Gender {
  value: string;
  viewValue: string;
}

export interface MaritalStatus {
  value: string;
  viewValue: string;
}

export interface LegalDocumentType {
  value: string;
  viewValue: string;
  idExpiry: boolean;
  idIssueCountry: boolean;
  placeholder: string;
}

export const enum SUPPORTED_COUNTRIES {
  UG = 'Uganda',
  KE = 'Kenya',
  GH = 'Ghana',
  TH = 'Tanzania',
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  countriesUrl = environment.apiUrl + '/support/countries';
  programUrl = environment.apiUrl + '/support/programs';

  constructor(private http: HttpClient) { }

  getCountries(){
    return this.http.get<Country[]>(this.countriesUrl);
  }

  getProgram(){
    return this.http.get<Country[]>(this.programUrl);
  }
}


export const titles: Title[] = [
  { value: 'MR', viewValue: 'Mr' },
  { value: 'MRS', viewValue: 'Mrs' },
  { value: 'MISS', viewValue: 'Miss' },
  { value: 'DR', viewValue: 'Dr' },
  { value: 'CHIEF', viewValue: 'Chief' },
  { value: 'SIR', viewValue: 'Sir' },
  { value: 'MS', viewValue: 'Ms' },
];