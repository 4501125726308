import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthScreen } from '../models/auth-screen';
import { LoginScreen, SignupScreen, NoAuthScreen, BothScreen } from '../actions/auth.actions';
import { authScreen } from '../reducers/auth.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {

  constructor(private store:Store<{authScreen:AuthScreen}>) { }

  setLoginScreen(){
    this.store.dispatch(new LoginScreen());
  }

  setSignupScreen(){
    this.store.dispatch(new SignupScreen());
  }

  setNoAuthScreen(){
    this.store.dispatch(new NoAuthScreen());
  }

  setBothScreen(){
    this.store.dispatch(new BothScreen());
  }

  getAuthScreen(){
    return this.store.select(authScreen).pipe();
  }
}
