import { Action } from "@ngrx/store";
import { Person } from "../../interfaces/person";

export enum ActionTypes{
    GetPersonAPI = '[Person] Get Person API',
    SetPerson = '[Person] Set Person',
    ClearPerson = '[Person] Clear Person',
    UpdateAddressAPI = '[Person] Update Address API',
    UpdateLegalAPI = '[Person] Update Legal API'
}

export class GetPersonAPI implements Action{
    readonly type = ActionTypes.GetPersonAPI;

    constructor(){}
}

export class SetPerson implements Action{
    readonly type = ActionTypes.SetPerson;

    constructor(public payload:Person){}
}

export class ClearPerson implements Action{
    readonly type = ActionTypes.ClearPerson;

    constructor(){}
}

export class UpdateAddressAPI implements Action{
    readonly type = ActionTypes.UpdateAddressAPI;

    constructor(public person:Person){}

}

export class UpdateLegalAPI implements Action{
    readonly type = ActionTypes.UpdateLegalAPI;

    constructor(public person:Person){}

}

export type ActionsUnion = GetPersonAPI|SetPerson|UpdateAddressAPI|UpdateLegalAPI|ClearPerson;
