<mat-toolbar>
    <mat-toolbar-row>
        <div class="logo"></div>
        <div class="navbar">
        	<a class="navbar__link"  routerLink="/dashboard" routerLinkActive="navbar__link--active" >Cards</a>
        	<a class="navbar__link"  routerLink="/support" routerLinkActive="navbar__link--active">Support Centre</a>
        </div>
        <div class="actions">
			<button class="logout" (click)="logout()" mat-stroked-button>Log Out</button>
        </div>
        <div class="mobile-navbar">
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item routerLink="/dashboard" routerLinkActive="mobile-navbar__link--active">Cards</button>
              <button mat-menu-item routerLink="/profile" routerLinkActive="mobile-navbar__link--active">Profile</button>
              <button mat-menu-item routerLink="/support" routerLinkActive="mobile-navbar__link--active">Support Centre</button>
              <button mat-menu-item (click)="logout()">Log Out</button>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<cp-alert-header></cp-alert-header>