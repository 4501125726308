import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'cp-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, AfterViewInit {

  constructor(private elementRef:ElementRef) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void{
  	this.elementRef.nativeElement.ownerDocument.body.classList.add("auth");
  }

}
