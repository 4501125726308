import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { HttpClientModule } from "@angular/common/http";
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { ContentFooterComponent } from './components/content-footer/content-footer.component';


import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { AlertHeaderComponent } from './components/alert-header/alert-header.component';
import {  MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    NotFoundComponent,AuthFooterComponent,AuthHeaderComponent,ContentHeaderComponent,ContentFooterComponent, AlertHeaderComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    
    //Material
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
  ],
  providers:[
  ],
  exports:[
    AuthFooterComponent,
    AuthHeaderComponent,
    ContentHeaderComponent,
    ContentFooterComponent
  ]
})
export class CoreModule { }
