import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';

import { tap } from "rxjs/operators";
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { SessionTimeoutService } from '../services/session-timeout.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(
    private router:Router,
    private authService:AuthenticationService,
    private session: SessionTimeoutService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    
    const session = this.authService.getSessionkey();

    if(session) {
      request = request.clone({ headers: request.headers.set('X-SESSION-KEY', session) });
      this.session.startTimer()
    }

    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
          return event;
        },
        (error:any)=>{
          if (error instanceof HttpErrorResponse) {
            if ([401, 403].indexOf(error.status) !== -1) {
              if (error.error.ErrorResponse && error.error.ErrorResponse.errorCode) {
                if (error.error.ErrorResponse.errorCode === 'r13') {
                  this.authService.removeSessionKey();
                  this.router.navigate(['login']);
                }
              } else if (error.error.message === 'Unauthorized') {
                this.authService.removeSessionKey();
                this.router.navigate(['login']);
              }
            }
          }
        }
      ),
    )

  }
}
