import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { map } from 'rxjs/operators';

export class Balance{
  walletNumber:string;
  walletCurrency:string;
  ledgerBalance:string;
  availableBalance:number;

}

export class Balances{
  rrn:string;
  stan:string;
  balances:Balance[];
}

@Injectable({
  providedIn: 'root'
})
export class BalancesService {

  constructor(private http:HttpClient) { }
  
  balanceUrl = environment.apiUrl + '/cards/balance?card=';
  
  getBalances(id){
    return this.http.get<Balances>(this.balanceUrl+id).pipe();
  }

  getChunkedBalances(id){
    return this.http.get<Balances>(this.balanceUrl+id).pipe(map(val => this.chunkArray(val.balances,2)));
  }

  chunkArray(myArray, chunk_size){
      const results = [];
      
      while (myArray.length) {
          results.push(myArray.splice(0, chunk_size));
      }
      
      return results;
  }
}
