import { ActionsUnion, ActionTypes } from '../actions/config.actions';
import { createFeatureSelector } from '@ngrx/store';
import { Country, Program } from '../../api/config.service';

export interface ConfigState{
    countries:Country[],
    program:Program
}
const initialState:ConfigState = null;

export function reducer(state:ConfigState = initialState,action:ActionsUnion){
    switch(action.type){
        case ActionTypes.LoadCountries:{
            return {
                ...state,
                countries:action.payload
            }
        }
        case ActionTypes.LoadPrograms:{
            return {
                ...state,
                program:action.payload
            }
        }
        default:
            return state;
    }    
}

export const configSelector = createFeatureSelector<ConfigState>('config');