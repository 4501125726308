import { Injectable } from '@angular/core';
import { AuthStoreService } from './auth-store.service';
import { PersonsStoreService } from './persons-store.service';
import { CardsStoreService } from './cards-store.service';
import { AlertStoreService } from './alert-store.service';

@Injectable({
  providedIn: 'root'
})
export class ManageStoreService {

  constructor(
    private authStoreService:AuthStoreService,
    private personStoreService:PersonsStoreService,
    private cardsStoreService:CardsStoreService,
    private alertStoreService: AlertStoreService
  ) { }

  clearStore(){
    this.cardsStoreService.clearCards();
    this.personStoreService.clearPerson();
    this.alertStoreService.clearAlert();
  }
}
