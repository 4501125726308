import { Action } from "@ngrx/store";

export enum ActionTypes{
    LoginScreen = '[Auth Screen] Login',
    SignupScreen = '[Auth Screen] Signup',
    NoAuthScreen = '[Auth Screen] NoAuth',
    BothScreen = '[Auth Screen] Both',
}

export class LoginScreen implements Action{
    readonly type = ActionTypes.LoginScreen;
}

export class SignupScreen implements Action{
    readonly type = ActionTypes.SignupScreen;
}

export class NoAuthScreen implements Action{
    readonly type = ActionTypes.NoAuthScreen;
}

export class BothScreen implements Action{
    readonly type = ActionTypes.BothScreen;
}

export type ActionsUnion = LoginScreen|SignupScreen|NoAuthScreen|BothScreen;