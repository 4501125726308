import { ActionTypes, ActionsUnion } from "../actions/persons.actions";
import { Person } from "../../interfaces/person";
import {createFeatureSelector, createSelector} from '@ngrx/store';

const initialState:Person = {
    id: null,
    title:'',
    firstName:'',
    lastName:'',
    maidenName:'',
    email:'',
    newEmail: '',
    emailStatus: '',
    idc:'',
    mobile:'',
    gender:'',
    maritalStatus: '',
    idType: '',
    idValue: '',
    idIssueCountry:'',
    idExpiry:'',
    dob: '',
    country: '',
    city: '',
    line1: '',
    line2: '',
    addressId: 0,
    address: '',
    addresses: [{id: 0, line1: '', line2: '', country: '', city: '', addressType: ''}],
    personStatus: {
        id: 0,
        name: null,
        description: null
    },
    personType: {
        id: 0,
        name: null,
        description: null
    },
    idDocument:null,
    verificationDocument:null,
    programs:[],
    personVelocity: null,
    profilePic: null
}

const getPersonState = createFeatureSelector<Person>('person');

export const getPerson = createSelector(
  getPersonState,
  (person:Person)=> person
);

export const getPersonVelocity = createSelector(
    getPerson,
    (person:Person)=> person.personVelocity
)

export function reducer(state:Person=initialState,action:ActionsUnion){
    switch(action.type){
        case ActionTypes.SetPerson:{
            return action.payload;
        }
        case ActionTypes.ClearPerson:{
            return initialState;
        }
        default:
            return state;
    }
}
