<div class="container"> 
  <div class="img404">
    <img src="/assets/images/404-icon.svg" alt="">
  </div>
  <div class="message">
    <h1>Oh Scobberlotcher!!!</h1>
    <p>It seems this page went to live in a castle in the sky!</p>
  </div>
  <div class="btn-action">
    <button mat-raised-button [routerLink]="['/login']">Go to Home Page</button>
  </div>
</div>