import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertStoreService } from '../../store/services/alert-store.service';
import { Subscription } from 'rxjs';
import { Alert } from '../../store/models/alert';

@Component({
  selector: 'cp-alert-header',
  templateUrl: './alert-header.component.html',
  styleUrls: ['./alert-header.component.scss']
})
export class AlertHeaderComponent implements OnInit, OnDestroy {

  toolBarClass: string = 'green-toolbar';
  alert: Alert;
  subscription: Subscription = new Subscription();

  constructor(private alertStoreService: AlertStoreService) { }

  ngOnInit() {
    let alertSub = this.alertStoreService.getAlert().subscribe(
      (alert) => {
        this.alert = alert;
      } 
    );

    this.subscription.add(alertSub);
  }

  setAction() {
    this.alertStoreService.setAlertAction(this.alert.actionText);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}