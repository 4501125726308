import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginModel } from 'src/app/auth/models/login.model';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ManageStoreService } from '../store/services/manage-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient, 
    private router: Router,
    private manageStoreService:ManageStoreService,
    ) { }

  item = 'session';
  authUrl = environment.authUrl;

  loginUrl = this.authUrl + '/users/login';
  logoutUrl = this.authUrl + '/users/logout';
  forgotPasswordStep2 = this.authUrl + '/users/forgotstep2';
  forgotPasswordStep1Url = this.authUrl + '/users/forgotstep1?username=';
  changePasswordUrl = this.authUrl + '/users/changepassword';
  verifyOtpURL = `${this.authUrl}/users/verify-otp`
  resendOtpURL = `${this.authUrl}/users/resend-otp`

  login(loginModel: LoginModel) {
    const encodePassword = btoa(loginModel.password);
    const xAuthKey = btoa(loginModel.username + ':' + encodePassword);
    const headers = new HttpHeaders()
      .set('X-SECURE-KEY', environment.apiKey)
      .set('X-AUTH-KEY', xAuthKey);

    const res = this.http.post(this.loginUrl, {}, { headers }).pipe(
      tap(val => {
        this.setSessionkey(val['X-SESSION-KEY']);
      })
    );
    return res;
  }

  verifyPassword(loginModel: LoginModel) {
    this.removeSessionKey();
    const encodePassword = btoa(loginModel.password);
    const xAuthKey = btoa(loginModel.username + ':' + encodePassword);
    const headers = new HttpHeaders()
      .set('X-SECURE-KEY', environment.apiKey)
      .set('X-AUTH-KEY', xAuthKey);
    return this.http.post(this.loginUrl, {}, { headers }).pipe(
      tap(val => {
        this.setSessionkey(val['X-SESSION-KEY']);
      })
    );
  }

  updatePassword(loginModel: LoginModel) {
    const encodePassword = btoa(loginModel.password);
    const encodeNewPassword = btoa(loginModel.newPassword);
    const xAuthKey = btoa(loginModel.username + ':' + encodePassword + ':' + encodeNewPassword);
    const headers = new HttpHeaders()
      .set('X-SECURE-KEY', environment.apiKey)
      .set('X-AUTH-KEY', xAuthKey);
    return this.http.post(this.loginUrl, {}, { headers }).pipe(
      tap(val => {
        this.setSessionkey(val['X-SESSION-KEY']);
      })
    );
  }

  resetPassword(username) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-SECURE-KEY', environment.apiKey);
    return this.http.post(this.forgotPasswordStep1Url + username, {}, { headers }).pipe();
  }

  forgotPassword(loginModel: LoginModel) {
    const encodePassword = btoa(loginModel.password);
    const encodeNewPassword = btoa(loginModel.newPassword);
    const xAuthKey = btoa(loginModel.username + ':' + encodePassword + ':' + encodeNewPassword);
    const headers = new HttpHeaders()
      .set('X-SECURE-KEY', environment.apiKey)
      .set('X-AUTH-KEY', xAuthKey);
    return this.http.post(this.forgotPasswordStep2, {}, { headers }).pipe();
  }

  changePassword(loginModel: LoginModel) {
    const encodePassword = btoa(loginModel.password);
    const encodeNewPassword = btoa(loginModel.newPassword);
    const xAuthKey = btoa(loginModel.username + ':' + encodePassword + ':' + encodeNewPassword);
    const headers = new HttpHeaders()
      .set('X-AUTH-KEY', xAuthKey);
    return this.http.post(this.changePasswordUrl, {}, { headers }).pipe();
  }

  logout() {

    const res = this.http.delete(this.logoutUrl).subscribe();
    this.removeSessionKey();
    sessionStorage.removeItem('username');
    this.manageStoreService.clearStore();
    this.router.navigate(['']);

    return res;
  }

  public getSessionkey() {
    return localStorage.getItem(this.item);
  }

  public setSessionkey(key) {
    localStorage.setItem(this.item, key);
  }

  public removeSessionKey() {
    localStorage.removeItem(this.item);
  }

  public isLoggedIn(): boolean {
    if (this.getSessionkey()) {
      return true;
    } else {
      return false;
    }
  }

  validateOTP(payload, formKey) {
    const otp = `${payload.input1}${payload.input2}${payload.input3}${payload.input4}`;
    const token = sessionStorage.getItem('tempStoreKey');
    const data = { token, otp }

    const headers = new HttpHeaders()
    .set('X-SECURE-KEY', environment.apiKey)
    .set('X-FORM-KEY', btoa(formKey));

  return this.http.post(this.verifyOtpURL, data, { headers })
  .pipe(catchError(e => throwError(e)));
  }

  resendOTP(formKey) {
    const otp = null;
    const token = sessionStorage.getItem('tempStoreKey');
    const data = { token, otp }

    const headers = new HttpHeaders()
    .set('X-SECURE-KEY', environment.apiKey)
    .set('X-FORM-KEY', btoa(formKey));

  return this.http.post(this.resendOtpURL, data, { headers })
  .pipe(catchError(e => throwError(e)));
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }
}
