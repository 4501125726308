import { Injectable } from '@angular/core';
import * as fromCards from 'src/app/core/store/reducers/cards.reducer';
import { Store } from '@ngrx/store';
import { MiniStatementLoading, GetBalancesAPI, GetMiniStatementAPI, SetSelectedCard, GetCardsAPI, ClearBalancesAndMiniStatement, ClearCards, SetFilterDetails, UpdateCardAliasAPI, UnSelectCard } from '../actions/cards.actions';
import { CardFilterDetails } from '../models/card-filter-details';

@Injectable({
  providedIn: 'root'
})
export class CardsStoreService {

  constructor(private store:Store<{cards:fromCards.State}>) { }

  getCardsAPI(){
    return this.store.dispatch(new GetCardsAPI());
  }

  clearCards(){
    return this.store.dispatch(new ClearCards());
  }

  getAllCards(){
    return this.store.select(fromCards.selectAll);
  }

  isMiniStatementLoading(){
    return this.store.select(fromCards.getMiniStatementLoading);
  }

  getSelectedCard(){
    return this.store.select(fromCards.getSelectedCard);
  }
  
  setMiniStatementLoading(state:boolean){
    return this.store.dispatch(new MiniStatementLoading(state));
  }

  getBalancesAPI(cardId){
    return this.store.dispatch(new GetBalancesAPI(cardId));
  }

  getMiniStatementAPI(cardId:number,startDate:Date,endDate:Date){
    this.store.dispatch(new GetMiniStatementAPI(cardId,startDate,endDate));
  }

  getTransactionList() {
    return this.store.select(fromCards.getTransactionList)
  }

  setSelectedCard(cardId){
    return this.store.dispatch(new SetSelectedCard(cardId));
  }

  unSelectCard(){
    return this.store.dispatch(new UnSelectCard());
  }
  
  isACardSelected(){
    return this.store.select(fromCards.cardSelected);
  }

  clearBalancesAndMiniStatement(cardId){
    return this.store.dispatch(new ClearBalancesAndMiniStatement(cardId,{miniStatement:null,balances:null}));
  }

  getNonSelectedCards(){
    return this.store.select<fromCards.CardStore[]>(fromCards.getNonSelectedCards);
  }

  setCardFilters(cardFilters: CardFilterDetails){
    return this.store.dispatch(new SetFilterDetails(cardFilters));
  }

  getFilteredCards(){
    return this.store.select<fromCards.CardStore[]>(fromCards.getFilteredCards);
  }

  updateCardAliasAPI(id: number, updatedAlias: string){
    return this.store.dispatch(new UpdateCardAliasAPI(id, updatedAlias));
  }
  
}
