import * as moment from "moment";

export const dateStringMMDDYYYY = (date:any) => {
    date = convertDate(date);
    let month = date.getMonth()+1;
    return `${(month < 10? '0'+month:month)}${date.getDate() < 10? '0'+date.getDate():date.getDate()}${date.getFullYear()}`;
}   

export const formatDateWithSlash = (date:any) => {
    date = convertDate(date);
    let month = date.getMonth()+1;
    return `${(month < 10? '0'+month:month)}/${date.getDate() < 10? '0'+date.getDate():date.getDate()}/${date.getFullYear()}`;
}

const numberOfMonths = 6;

export const totalMonths = (date:any,isAddition:boolean) => {
    date = convertDate(date);

    let currentDate = new Date(date.getFullYear(),date.getMonth(),date.getDate());

    let year:number;
    
    if(isAddition){
        year =  (date.getMonth() >= 6 ? date.getFullYear()+1:date.getFullYear());
    }else{
        year =  (date.getMonth() <= 5 ? date.getFullYear()-1:date.getFullYear());
    }

    let month = (isAddition?currentDate.getMonth()+numberOfMonths:currentDate.getMonth()-numberOfMonths)%12;
    currentDate.setMonth(month);
    currentDate.setFullYear(year);
    return currentDate;
}

export const convertDate = (date: any) => {
    if(date instanceof moment) {
        return moment(date).toDate();
    }
    return date;
}