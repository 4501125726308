import { Action } from "@ngrx/store";
import { Card } from "../../api/cards.service";
import { CardStore } from "../reducers/cards.reducer";
import { CardFilterDetails } from "../models/card-filter-details";
import { Update } from '@ngrx/entity';

export enum ActionTypes {
    GetCardsAPI = '[Cards] Get Cards API',
    SetCards = '[Cards] Set Cards',
    ClearCards = '[Cards] Clear',
    SetSelectedCard = '[Cards] Set',
    GetBalancesAPI = '[Cards] Get Balances API',
    SetBalances = '[Cards] Set Balances',
    GetMiniStatementAPI = '[Cards] Get MiniStatement API',
    SetMiniStatement = '[Cards] Set MiniStatement',
    UnSelectCard = '[Cards] Un Select Card',
    MiniStatementLoading = '[Cards] MiniStatement Loading Complete',
    ClearBalancesAndMiniStatement = '[Cards] Clear Balances and MiniStatement on selected card',
    SetCardFilters = '[Cards] Set Card Dashboard Filter Details',
    UpdateCardAliasAPI = '[Cards] Set Updated Card Alias On API',
    UpdateCardAliasSuccess = '[Cards] Load Updated Card Alias After API Success'
}

export class GetCardsAPI implements Action{
    readonly type = ActionTypes.GetCardsAPI;

    constructor(){}
}

export class ClearCards implements Action{
    readonly type = ActionTypes.ClearCards;

    constructor(){}
}

export class SetCards implements Action{
    readonly type = ActionTypes.SetCards;

    constructor(public payload:Card[]){}
}

export class SetSelectedCard implements Action{
    readonly type = ActionTypes.SetSelectedCard;

    constructor(public id:number){}
}

export class UnSelectCard implements Action{
    readonly type = ActionTypes.UnSelectCard;

    constructor(){}
}

export class SetFilterDetails implements Action{
    readonly type = ActionTypes.SetCardFilters;

    constructor(public filterDetails: CardFilterDetails){}
}

export class GetBalancesAPI implements Action{
    readonly type = ActionTypes.GetBalancesAPI;

    constructor(public id:number){}
}

export class SetBalances implements Action{
    readonly type = ActionTypes.SetBalances;

    constructor(public id:number,public balances:Partial<CardStore>){}
}

export class GetMiniStatementAPI implements Action{
    readonly type = ActionTypes.GetMiniStatementAPI;

    constructor(public id:number,public startDate:Date,public endDate:Date){}
}

export class UpdateCardAliasAPI implements Action {
    readonly type = ActionTypes.UpdateCardAliasAPI;

    constructor(public id: number, public updatedAlias: string){}
}

export class UpdateCardAliasSuccess implements Action {
    readonly type = ActionTypes.UpdateCardAliasSuccess;

    constructor(public id:number, public alias: Partial<CardStore>){}
}

export class SetMiniStatement implements Action{
    readonly type = ActionTypes.SetMiniStatement;

    constructor(public id:number,public miniStatement:Partial<CardStore>){}
}
export class MiniStatementLoading implements Action{
    readonly type = ActionTypes.MiniStatementLoading;

    constructor(public miniStatementLoading:boolean){}
}

export class ClearBalancesAndMiniStatement implements Action{
    readonly type = ActionTypes.ClearBalancesAndMiniStatement;

    constructor(public id:number,public balancesAndMiniStatements:Partial<CardStore>){}
}

export type ActionsUnion = GetCardsAPI|
            SetCards|
            ClearCards|
            SetSelectedCard|
            UnSelectCard|
            GetBalancesAPI|
            SetBalances|
            GetMiniStatementAPI|
            SetMiniStatement|
            MiniStatementLoading|
            ClearBalancesAndMiniStatement|
            SetFilterDetails|
            UpdateCardAliasAPI|
            UpdateCardAliasSuccess;
