export enum TypeFilter {
    allCardTypes = 'All Card Types',
    virtualCards = 'Virtual Cards',
    physicalCards = 'Physical Cards'
}

export enum DateFilter {
    allTime = 'All Time',
    thisWeek = 'This Week',
    lastWeek = 'Last Week',
    thisMonth = 'This Month',
    lastMonth = 'Last Month',
    thisYear = 'This Year',
    lastYear = 'Last Year',
    older = 'Older'
}