import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthScreen } from 'src/app/core/store/models/auth-screen';
import { Observable } from 'rxjs';
import { AuthStoreService } from '../../store/services/auth-store.service';

@Component({
  selector: 'cp-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit {
  screen$: Observable<AuthScreen>;

  constructor(private router:Router,private authStoreService:AuthStoreService) {
    this.screen$ = this.authStoreService.getAuthScreen();
  }

  
  ngOnInit() {
  }

  loginScreen(){
    this.router.navigate(['login']);
    this.authStoreService.setLoginScreen();
  }

  signupScreen(){
    this.router.navigate(['create-account']);
    this.authStoreService.setSignupScreen();
  }
}
