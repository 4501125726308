<mat-toolbar>
    <mat-toolbar-row>
    	<div class="links">
			<a [routerLink]="['/support']">Support</a>
			<a [routerLink]="['/support/terms-and-conditions']">Terms and Conditions</a>
            <a href="https://www.dpogroup.com/africa/terms-conditions/" target="_blank">DPO Terms and Conditions</a>
			<a [routerLink]="['/support/contact']">Contact Us</a>
    	</div>
    	<div class="legal">
    		<div class="legal__logo"></div>
    		<div class="legal__text">
    			DPO Card is a product by DPO Group &copy; Copyright DPO {{copyrightdate}} &ndash; All Rights Reserved
    		</div>
    	</div>
    </mat-toolbar-row>
</mat-toolbar>
