import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Observable, EMPTY } from "rxjs";
import { switchMap, map, catchError} from "rxjs/operators";
import { Action } from '@ngrx/store';
import { ActionTypes } from '../actions/config.actions';
import { ConfigService } from '../../api/config.service';

@Injectable()
export class ConfigEffects {

  constructor(
    private actions$: Actions,
    private configService:ConfigService) {}

  @Effect()
  getCountries$:Observable<Action> = this.actions$.pipe(
      ofType(ActionTypes.GetCountries),
      switchMap(action => {
        return this.configService.getCountries().pipe(
            map(data => ({type:ActionTypes.LoadCountries,payload:data})),
            catchError(() => EMPTY)
        )
      })
  );

  @Effect()
  getProgram$:Observable<Action> = this.actions$.pipe(
      ofType(ActionTypes.GetPrograms),
      switchMap(action => {
        return this.configService.getProgram().pipe(
            map(data => ({type:ActionTypes.LoadPrograms,payload:data})),
            catchError(() => EMPTY)
        )
      })
  );
}