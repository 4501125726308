import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "../../../environments/environment";
import { dateStringMMDDYYYY } from '../utils/date-util';
export interface Transaction{
  acquirerTransactionDateTime:string;
  businessDateOfTransaction:string;
  issuerTransactionDateTime:string;
  transactionLabel:string;
  transactionReferenceNumber:string;
  transactionSign:string;
  txnCurrency:string;
  txnAmount:number;
}

export interface  MiniStatement{
    rrn:string;
    stan:string;
    transactions:Transaction[];
}

export interface EmailMinistatementRequest {
  fromDate: string;
  toDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class MiniStatementService {

  constructor(private http:HttpClient) { }

  getMiniStatements(id:number,startDate:Date,endDate:Date){
    
    let miniStatementUrl = environment.apiUrl + `/cards/ministatement?from=${dateStringMMDDYYYY(startDate)}&to=${dateStringMMDDYYYY(endDate)}&card=${id}`;

    return this.http.get<MiniStatement>(miniStatementUrl).pipe();
  }

  emailMinistatement(id:number, startDate:Date, endDate:Date) {
    const data: EmailMinistatementRequest = { 
      fromDate: dateStringMMDDYYYY(startDate),
      toDate: dateStringMMDDYYYY(endDate)
    }
    
    const url = environment.apiUrl + `/cards/${id}/ministatement`;

    return this.http.post(url, data).pipe();
  }
}
