import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Observable, EMPTY } from "rxjs";
import { switchMap, map, catchError} from "rxjs/operators";
import { ActionTypes, GetBalancesAPI, GetMiniStatementAPI, UpdateCardAliasAPI } from "../actions/cards.actions";
import { Action } from '@ngrx/store';
import { CardsService } from '../../api/cards.service';
import { BalancesService } from '../../api/balances.service';
import { MiniStatementService } from '../../api/mini-statement.service';

@Injectable()
export class CardEffects {

  constructor(
    private actions$: Actions,
    private cardsService:CardsService,
    private balancesService:BalancesService,
    private miniStatementService:MiniStatementService) {}

  @Effect()
  getCards$:Observable<Action> = this.actions$.pipe(
      ofType(ActionTypes.GetCardsAPI),
      switchMap(action => {
        return this.cardsService.getCards().pipe(
            map(data => ({type:ActionTypes.SetCards,payload:data})),
            catchError(() => EMPTY)
        )
      })
  );

  @Effect()
  getBalances$:Observable<Action> = this.actions$.pipe(
      ofType(ActionTypes.GetBalancesAPI),
      switchMap((action:GetBalancesAPI) => {
          return this.balancesService.getChunkedBalances(action.id).pipe(
            map(data => ({type:ActionTypes.SetBalances,id:action.id,balances:{balances:data}})),
            catchError(() => EMPTY)
        )
      })
  );

  @Effect()
  getMiniStatement$:Observable<Action> = this.actions$.pipe(
      ofType(ActionTypes.GetMiniStatementAPI),
      switchMap((action:GetMiniStatementAPI) => {
          return this.miniStatementService.getMiniStatements(action.id,action.startDate,action.endDate).pipe(
            map(data => ({type:ActionTypes.SetMiniStatement,id:action.id,miniStatement:{miniStatement:data}})),
            catchError(() => EMPTY)
        )
      })
  );

  @Effect()
  UpdateCardAliasAPI$:Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.UpdateCardAliasAPI),
    switchMap((action:UpdateCardAliasAPI) => {
      return this.cardsService.updatedCardAlias(action.id, action.updatedAlias).pipe(
        map(data => ({type:ActionTypes.UpdateCardAliasSuccess,id:action.id,alias:{alias:data.alias} })),
        catchError(() => EMPTY)
      );
    })
  );

}