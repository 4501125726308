import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/components/not-found/not-found.component';

const routes: Routes = [
  {
    path:'',
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path:'dashboard',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path:'support',
    // canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
      }
    ]
  },
  {
    path:'card-settings',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./card-settings/card-settings.module').then(m => m.CardSettingsModule)
      }
    ]
  },
  {
    path:'profile',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
      }
    ]
  },
  {
    path:'onboarding',
    canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
      }
    ]
  },
  {
    path:'purchase',
    children: [
      {
        path: '',
        loadChildren: () => import('./purchase/purchase.module').then(m => m.PurchaseModule)
      }
    ]
  },
  {
    path:'transfer',
    children: [
      {
        path: '',
        loadChildren: () => import('./transfer/transfer.module').then(m => m.TransferModule)
      }
    ]
  },
  {
    path:'load-funds',
    children: [
      {
        path: '',
        loadChildren: () => import('./load-funds/load-funds.module').then(m => m.LoadFundsModule)
      }
    ]
  },
  {
    path:'**',component:NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
