import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface Status {
  id: number;
  name: string;
  description: string;
}

export interface Type {
  id: number;
  name: string;
  description: string;
}

export interface Velocity {
  totalAmount: number;
  totalReloads: number;
  currency: string;
}

export class Card {
  id: number;
  pan: string;
  created: Date;
  expiry: string;
  status: Status;
  type: Type;
  alias: string;
  velocity: Velocity;
}

export interface UpdateCardResponse {
  rrn: string;
  stan: string;
  resultCode: string;
  resultMessage: string;
}

export class VirtualCardDetail{
  holder:string;
  pan:string;
  cvv:string;
  expiryDate:string;
  walletNumber:string;
}

export interface WalletToWalletRequest {
  sourceCardId: number,
  destinationCardId: number,
  amount: string,
  remarks: string
}

export interface CardTransferRequest {
  sourceCardId: number,
  sourceCurrency: string,
  destinationCardId: number,
  destinationCurrency: string,
  transferAmount: string
}

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  
  constructor(private http: HttpClient) { }

  cardsUrl = environment.apiUrl + '/cards';
  blockUnblockUrl =environment.apiUrl + '/cards/block-unblock';
  updateCardUrl = environment.apiUrl + '/cards/card';
  cardActivationUrl = environment.apiUrl + '/cards/activate';
  virtualCardDetailUrl = environment.apiUrl + '/cards/detail?card=';
  walletTransferUrl = environment.apiUrl + '/wallets/transfer'; //deprecated
  cardTransferUrl = environment.apiUrl + '/cards/transfer'

  getCards() {
    return this.http.get<Card[]>(this.cardsUrl).pipe();
  }

  blockUnblockCard(id, status) {
    return this.http.put<UpdateCardResponse>(this.blockUnblockUrl,
      {
        'cardId': id,
        'status': status
      }).pipe();
  }

  updateCard(id, status, message) {
    return this.http.put<UpdateCardResponse>(this.updateCardUrl,
      {
        'cardId': id,
        'status': status,
        'remarks': message
      }).pipe();
  }

  activateCard(data: any) {
    return this.http.post<UpdateCardResponse>(this.cardActivationUrl, data).pipe();
  }
  
  getVirtualCardDetails(id:number){
    return this.http.get(this.virtualCardDetailUrl+id);
  }

  updatedCardAlias(id: number, alias: string) {
    return this.http.put<Card>(`${this.cardsUrl}/${id}/alias`, 
      {
        'cardId': id, 
        'alias': alias
      }).pipe();
  }

  //deprecated
  processTransaction(req: WalletToWalletRequest) {
    return this.http.post<UpdateCardResponse>(this.walletTransferUrl, req ).pipe();
  }

  renewVirtualCard(cardId: number) {
    return this.http.post<Card>(`${this.cardsUrl}/${cardId}/renew`, {});
  }

  cardTransfer(req: CardTransferRequest) {
    return this.http.post(`${this.cardTransferUrl}`, req).pipe();
  }
}
