import * as authScreen from "./auth.reducer";
import * as person from "./persons.reducer";
import * as cards from './cards.reducer';
import * as config from './config.reducer';
import * as alert from './alert.reducer';

export const reducers = {
    authScreen:authScreen.reducer,
    person:person.reducer,
    cards: cards.reducer,
    config: config.reducer,
    alert: alert.reducer
};