import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'cp-spin-timer',
  templateUrl: './spin-timer.component.html',
  styleUrls: ['./spin-timer.component.scss']
})
export class SpinTimerComponent implements OnInit, OnDestroy {

  FULL_DASH_ARRAY = 283;
  
  @Input() timeLimit = 3600;
  @Input() warningThreshold = 300;
  @Input() alertThreshold = 180;
  

  COLOR_CODES = {
    info: {
      color: "green"
    },
    warning: {
      color: "orange",
      threshold: this.warningThreshold
    },
    alert: {
      color: "red",
      threshold: this.alertThreshold
    }
  };

  timePassed = 0;
  timeLeft = this.timeLimit;
  timerInterval = null;
  remainingPathColor = this.COLOR_CODES.info.color;

  subscriptions: Subscription = new Subscription();
  constructor() { }

  ngOnInit() {
    this.startTimer();
  }

  ngOnDestroy() {
    this.onTimesUp();
  }

  onTimesUp() {
    clearInterval(this.timerInterval);
  }
  
  startTimer() {
    this.timerInterval = setInterval(() => {
      this.timePassed = this.timePassed += 1;
      this.timeLeft = this.timeLimit - this.timePassed;
      document.getElementById("base-timer-label").innerHTML = this.formatTime(
        this.timeLeft
      );
      this.setCircleDasharray();
      this.setRemainingPathColor(this.timeLeft);
  
      if (this.timeLeft === 0) {
        this.onTimesUp();
      }
    }, 1000);
  }
  
  formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds:any = time % 60;
  
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
  
    return `${minutes}:${seconds}`;
  }
  setRemainingPathColor(timeLeft) {
    const { alert, warning, info } = this.COLOR_CODES;

    if (timeLeft <= this.alertThreshold) {
      document
        .getElementById("base-timer-path-remaining")
        .classList.remove(warning.color);

      document
        .getElementById("base-timer-path-remaining")
        .classList.add(alert.color);

    } else if (timeLeft <= this.warningThreshold) {
      document
        .getElementById("base-timer-path-remaining")
        .classList.remove(info.color);

      document
        .getElementById("base-timer-path-remaining")
        .classList.add(warning.color);
    }
  }
  
  calculateTimeFraction() {
    const rawTimeFraction = this.timeLeft / this.timeLimit;
    return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
  }
  
   setCircleDasharray () {
    const circleDasharray = `${(
      this.calculateTimeFraction() * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    document
      .getElementById("base-timer-path-remaining")
      .setAttribute("stroke-dasharray", circleDasharray);
  }
  
}
