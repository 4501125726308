import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from "./core/core.module";

//Store
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers } from "./core/store/reducers/index";

import { StoreDevtoolsModule } from "@ngrx/store-devtools";
//Material
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { effects } from './core/store/effects';
import { httpInterceptors } from 'src/app/core/interceptors/index';
import { MatDialogRef } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    //angular
    BrowserModule,
    BrowserAnimationsModule,

    //app
    AppRoutingModule,

    //core & shared
    CoreModule,

    //store
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge:25
    }),

    //material
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
  ],
  providers: [
    httpInterceptors,
    { provide: MatDialogRef, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
