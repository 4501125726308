import {ActionTypes,ActionsUnion} from "../actions/auth.actions";
import { AuthScreen } from "../models/auth-screen";
import { createFeatureSelector } from "@ngrx/store";

const initialState:AuthScreen = {
    login:false,
    signup:false,
    noAuth:false,
    both:false
}

export function reducer(state:AuthScreen = initialState,action: ActionsUnion):AuthScreen{
    switch(action.type){
        case ActionTypes.LoginScreen:{
            return {
                ...state,
                login:true,
                signup:false,
                both:false,
            }
        }
        case ActionTypes.SignupScreen:{
            return {
                ...state,
                login:false,
                signup:true,
                both:false,
            }
        }
        case ActionTypes.NoAuthScreen:{
            return {
                ...state,
                login:false,
                signup:false,
                both:false,
            }
        }
        case ActionTypes.BothScreen:{
            return {
                ...state,
                login:false,
                signup:false,
                both:true,
            }
        }
        default:
            return state;
    }
}

export const authScreen = createFeatureSelector<AuthScreen>('authScreen');