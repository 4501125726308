import { ActionsUnion,ActionTypes } from "../actions/cards.actions";
import { Card } from "../../api/cards.service";
import { createFeatureSelector,createSelector} from '@ngrx/store';
import { Balance } from "../../api/balances.service";
import { MiniStatement } from "../../api/mini-statement.service";
import { EntityState,createEntityAdapter, Dictionary} from "@ngrx/entity";
import { TypeFilter, DateFilter } from '../../enums/filters';
import { CardType } from '../../enums/cardType';
import * as moment from 'moment';

export class CardStore extends Card {
    miniStatement?:MiniStatement;
    balances?:Balance[][];
}

export interface CardState{
    selectedCard:CardStore;
    cards:CardStore[];
    cardsLoaded:boolean;
}

export const  cardsAdapter = createEntityAdapter<CardStore>();

export interface State extends EntityState<CardStore>{
    selectedCardId:number;
    miniStatementLoading:boolean;
    // card dashboard filters
    typeFilter: string;
    dateFilter: string;
    lastFourDigitsFilter: string;
    aliasFilter: string;
} 

const defaultCard = {
    ids:[],
    entities:{},
    selectedCardId:0,
    miniStatementLoading:true,
    typeFilter: TypeFilter.allCardTypes,
    dateFilter: DateFilter.allTime,
    lastFourDigitsFilter: '',
    aliasFilter: ''
}

export const initialState:State = cardsAdapter.getInitialState(defaultCard);

const getCardsState = createFeatureSelector<State>('cards');

export const {
    selectIds,
    selectAll,
    selectEntities,
    selectTotal
} = cardsAdapter.getSelectors(getCardsState);

export const getSelectedId = createSelector(
    getCardsState,
    (card:State)=> card.selectedCardId
);

export const getMiniStatementLoading = createSelector(
    getCardsState,
    (card:State)=>card.miniStatementLoading
);

export const getNonSelectedCards = createSelector(
    selectAll,
    getSelectedId,
    (allCards:CardStore[],id:number) => allCards.filter( card => card.id !== id) 
);

export const getSelectedCard = createSelector(
    selectEntities,
    getSelectedId,
    (allCards:Dictionary<CardStore>,id:number) => allCards[id]
);

export const getTransactionList = createSelector(
    getSelectedCard,
    (selectedCard:CardStore)=>{
        if(selectedCard && selectedCard.miniStatement){
            return selectedCard.miniStatement.transactions
        }
        return []
    }
)

export const cardSelected = createSelector(
    getSelectedId,
    (id:number) => id !== 0
);

export const getFilteredCards = createSelector (
    getCardsState,
    getNonSelectedCards,
    (cardState:State, nonSelectedCards:CardStore[]) => nonSelectedCards
    .filter( card => filterOnCardType(card.type.name, cardState.typeFilter) )
    .filter( card => card.pan.lastIndexOf(cardState.lastFourDigitsFilter) >= 12 )
    .filter( card => filterOnCreatedRange(card.created, cardState.dateFilter) )
    .filter( card => filterOnAlias (card.alias, cardState.aliasFilter) )
);

function filterOnCardType(type: string, typeFilter: string): boolean {
    switch(typeFilter) {
        case TypeFilter.allCardTypes:
            return true;
        case TypeFilter.virtualCards:
            return type === CardType.VIRTUAL;
        case TypeFilter.physicalCards:
            return type === CardType.PHYSICAL;
        default:
            return true;
    }
}

function filterOnCreatedRange(created: Date, range: string): boolean {
    const weeks = moment().diff(created, 'weeks', true);
    const months = moment().diff(created, 'months', true);
    const years = moment().diff(created, 'years', true);
    switch(range){
        case DateFilter.allTime:
            return true;
        case DateFilter.thisWeek:
            return weeks < 1;
        case DateFilter.lastWeek:
            return weeks > 1 && weeks < 2;
        case DateFilter.thisMonth:
            return months < 1;
        case DateFilter.lastMonth:
            return months > 1 && months < 2
        case DateFilter.thisYear:
            return years < 1;
        case DateFilter.lastYear:
            return years > 1 && years < 2;
        case DateFilter.older:
            return years > 2;
        default:
            return true;
    }
}

function filterOnAlias(alias: string, aliasFilter: string) {
    if (aliasFilter === "") { return true; }
    else {
        return alias ? alias.toLowerCase().indexOf(aliasFilter.toLowerCase())!==-1 : false;
    }
}

export function reducer(state:State = initialState,action:ActionsUnion) {
    switch(action.type){
        case ActionTypes.SetCards:{
            return cardsAdapter.addAll(action.payload,state);
        }
        case ActionTypes.ClearCards:{
            return initialState
        }
        case ActionTypes.SetSelectedCard:{
            return {
                ...state,
                miniStatementLoading: true,
                selectedCardId:action.id
            }
        }
        case ActionTypes.UnSelectCard:{
            return {
                ...state,
                selectedCardId:0
            }
        }
        case ActionTypes.SetCardFilters:{
            return {
                ...state,
                typeFilter:action.filterDetails.typeFilter,
                dateFilter:action.filterDetails.dateFilter,
                aliasFilter:action.filterDetails.aliasFilter,
                lastFourDigitsFilter:action.filterDetails.lastFourDigitsFilter
            }
        }
        case ActionTypes.UpdateCardAliasSuccess:{
            return cardsAdapter.updateOne({id:action.id,changes:action.alias},state);
        }
        case ActionTypes.SetBalances:{
            return cardsAdapter.updateOne({id:action.id,changes:action.balances},state);
        }
        case ActionTypes.SetMiniStatement:{
            return cardsAdapter.updateOne({id:action.id,changes:action.miniStatement},state);
        }
        case ActionTypes.MiniStatementLoading:{
            return {
                ...state,
                miniStatementLoading:action.miniStatementLoading
            }
        }
        case ActionTypes.ClearBalancesAndMiniStatement:{
            return cardsAdapter.updateOne({id:action.id,changes:action.balancesAndMiniStatements},state);
        }
        default:
            return state;
    }    
}