<div class="container">
    <h2 class="header">Are you still there?</h2>
    <p class="message">You will be logged out in...</p>
    
    <cp-spin-timer class="timer" timeLimit='60' warningThreshold='0' alertThreshold='0'></cp-spin-timer>
    
    <div class="row__buttons">
        <div><button mat-stroked-button (click)="logout()" class="button-secondary">Log me out</button></div>
        <div><button mat-flat-button (click)="refreshSession()" class="button-primary">I'm still here</button></div>
    </div>
</div>