import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Person } from '../../interfaces/person';
import { getPerson, getPersonVelocity } from '../reducers/persons.reducer';
import { GetPersonAPI, UpdateAddressAPI, UpdateLegalAPI, ClearPerson } from '../actions/persons.actions';

@Injectable({
  providedIn: 'root'
})
export class PersonsStoreService {

  constructor(public store: Store<{ person: Person }>) { }

  getPerson() {
    return this.store.select(getPerson);
  }

  getPersonVelocity() {
    return this.store.select(getPersonVelocity)
  }

  getPersonAPI() {
    this.store.dispatch(new GetPersonAPI());
  }

  clearPerson() {
    this.store.dispatch(new ClearPerson());
  }

  updateAddressAPI(person: Person) {
    this.store.dispatch(new UpdateAddressAPI(person));
  }

  updateLegalAPI(person: Person) {
    this.store.dispatch(new UpdateLegalAPI(person));
  }
}
