import { Action } from "@ngrx/store";
import { Country } from '../../api/config.service';

export enum ActionTypes{
    GetCountries = '[Country] Get',
    LoadCountries = '[Country] Load',
    GetPrograms = '[Programs] Get',
    LoadPrograms = '[Programs] Load',
}

export class GetCountries implements Action{
    readonly type = ActionTypes.GetCountries;

    constructor(){}
}

export class LoadCountries implements Action{
    readonly type = ActionTypes.LoadCountries;

    constructor(public payload:Country[]){}
}

export class GetPrograms implements Action{
    readonly type = ActionTypes.GetPrograms;

    constructor(){}
}

export class LoadPrograms implements Action{
    readonly type = ActionTypes.LoadPrograms;

    constructor(public payload:Country[]){}
}

export type ActionsUnion = GetCountries|LoadCountries|GetPrograms|LoadPrograms;